import React from 'react';

import Layout from '@src/components/layout';
import { pages } from '@src/constants/pages';
import { events } from '@src/constants/gaEvents';
import { customEvent } from '@src/helpers/gtag';

import icon1 from '@src/images/lp/icon1-years.svg';
import icon2 from '@src/images/lp/icon2-fast.svg';
import icon3 from '@src/images/lp/icon3-usa.svg';
import icon4 from '@src/images/lp/icon4-ups.svg';

const pcbPrototypesNoRiskGuaranteed = () => (
  <Layout pageName={pages.landingPageNoRisk.name}>
    <div className="v-landing-page">
      <section>
        <div className="c-ribbon">
          Try at no risk!
        </div>
        <h1>fast PCB prototypes</h1>
        <h2>Workmanship guaranteed.</h2>
      </section>
      <section className="with-banner">
        <ul>
          <li><strong>Ideal for complex assemblies</strong></li>
          <li>Free instant quotes</li>
          <li>Largest parts inventory</li>
          <li>3 state-of-the-art SMT lines</li>
        </ul>
        <a
          className="c-btn c-btn--primary--accent"
          href="/"
          onClick={customEvent(events.landingPageNoRiskStartQuoting())}
        >
          Get Instant Quote
        </a>
      </section>
      <ol className="c-icon-list">
        <li>
          <img src={icon1} alt="" />
          Quality<br />&amp;&nbsp;Service
        </li>
        <li>
          <img src={icon2} alt="" />
          72-hour<br />Turnaround
        </li>
        <li>
          <img src={icon3} alt="" />
          Assembled&nbsp;in<br />USA
        </li>
        <li>
          <img src={icon4} alt="" />
          Free&nbsp;UPS<br />Shipping
        </li>
      </ol>
      <section>
        <h3>ADCOproto is a division of ADCO Circuits, a PCBA supplier in Michigan, USA.</h3>
        <ul>
          <li>Over 40 years of quality service.</li>
          <li>Class 3, HDI, and RF assembly capabilities.</li>
          <li>ISO9001, AS9100, IATF16949, ITAR</li>
          <li>Serving aerospace, telecom, medical device manufacturers, and more.</li>
        </ul>
        <a
          className="c-btn c-btn--primary--accent"
          href="/"
          onClick={customEvent(events.landingPageNoRiskStartQuoting())}
        >
          Get Instant Quote
        </a>
      </section>
    </div>
  </Layout>
);

export default pcbPrototypesNoRiskGuaranteed;
